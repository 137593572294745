import React, { useState, useRef } from 'react';
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { animateScroll as scroll } from "react-scroll"
import * as Yup from "yup";
import * as styles from './ContactForm.module.scss'
//  import axios from "axios";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//バリデーション
const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'お名前が短すぎます')
    .max(70, 'お名前が長すぎます')
    .required('お名前は必須項目です'),
  furigana: Yup.string()
    .min(2, 'フリガナが短すぎます')
    .max(70, 'フリガナが長すぎます')
    .required('フリガナは必須項目です'),
  companyname: Yup.string()
    .min(2, '会社名が短すぎます')
    .max(70, '会社名が長すぎます'),
  email: Yup.string()
    .email('メールアドレスが正しくありません。')
    .required('メールアドレスは必須項目です'),
  emailconfirm: Yup.string()
    .email('メールアドレスが正しくありません。')
    .oneOf([Yup.ref('email')], 'メールアドレスが一致しません。')
    .required('メールアドレスの確認は必須項目です'),
  address: Yup.string()
    .min(2, 'ご住所が短すぎます')
    .required('ご住所は必須項目です'),
  tel: Yup.string()
    .matches(phoneRegExp, '電話番号が正しくありません')
    .required('電話番号は必須項目です'),
  message: Yup.string()
    .required('お問い合わせ内容は必須項目です'),
});


const ContactForm = () => {
  const [toggleState, setToggle] = useState(false);
  const myRef = useRef(null)
  const handleClick = () => {
    setToggle(!toggleState);
    // scroll.scrollToTop();
    var scrlPos = myRef.current.offsetTop;
    scroll.scrollTo(scrlPos);
    // myRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    // console.log(myRef);
  };



  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }



  return (
    <div className={`${styles.ContactForm} ${toggleState ? styles.confirm : ""}`} ref={myRef}>
      <Formik
        initialValues={{
          'form-name': 'ContactForm', // NetlifyFormで必要な値
          'subject': '【お問い合わせがありました】',
          name: '',
          furigana: '',
          companyname: '',
          email: '',
          emailconfirm: '',
          address: '',
          tel: '',
          message: '',
          'bot-field': '', // NetlifyFormで必要な値
        }}
        validationSchema={ContactFormSchema}
        onSubmit={
          (values, actions) => {
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "ContactForm", ...values })
            })
              .then(() => {
                // alert('Success');
                navigate('/success');
                actions.resetForm()
              })
              .catch(() => {
                alert('Error');
              })
              .finally(() => actions.setSubmitting(false))
          }
        }
      >
        {({ errors, touched }) => (
          <Form id="ContactForm" data-netlify="true">
            <Field type="hidden" name="bot-field" /> {/* NetlifyFormで必要な値 */}
            <Field type="hidden" name="form-name" /> {/* NetlifyFormで必要な値 */}
            <Field type="hidden" name="subject" value={`【お問い合わせがありました】`} /> {/* NetlifyFormで必要な値 */}
            <div className={styles.ContactForm__item}>
              <label htmlFor="name">
                <span className={styles.ContactForm__title}>お名前</span>
                <Field name="name" className={styles.ContactForm__field} placeholder="山口　太郎" />
                {errors.name && touched.name ? <div className={styles.ContactForm__errormsg}>{errors.name}</div> : null}
              </label>
            </div>
            <div className={styles.ContactForm__item}>
              <label htmlFor="furigana">
                <span className={styles.ContactForm__title}>フリガナ</span>
                <Field name="furigana" className={styles.ContactForm__field} placeholder="ヤマグチ　タロウ" />
                {errors.furigana && touched.furigana ? <div className={styles.ContactForm__errormsg}>{errors.furigana}</div> : null}
              </label>
            </div>
            <div className={styles.ContactForm__item}>
              <label htmlFor="companyname">
                <span className={`${styles.ContactForm__title} ${styles.noafter}`}>会社名</span>
                <Field name="companyname" className={styles.ContactForm__field} placeholder="株式会社チキンヒーロー" />
              </label>
            </div>
            <div className={styles.ContactForm__item}>
              <label htmlFor="email">
                <span className={styles.ContactForm__title}>メールアドレス</span>
                <Field name="email" type="email" className={styles.ContactForm__field} placeholder="xxxx_1234@xxx.com" />
                {errors.email && touched.email ? <div className={styles.ContactForm__errormsg}>{errors.email}</div> : null}
              </label>
            </div>
            <div className={styles.ContactForm__item}>
              <label htmlFor="emailconfirm">
                <span className={styles.ContactForm__title}>メールアドレス（確認）</span>
                <Field name="emailconfirm" type="email" className={styles.ContactForm__field} placeholder="xxxx_1234@xxx.com" />
                {errors.emailconfirm && touched.emailconfirm ? <div className={styles.ContactForm__errormsg}>{errors.emailconfirm}</div> : null}
              </label>
            </div>
            <div className={styles.ContactForm__item}>
              <label htmlFor="address">
                <span className={styles.ContactForm__title}>ご住所</span>
                <Field name="address" className={styles.ContactForm__field} placeholder="ささみ市黒かしわ町1番1号" />
                {errors.address && touched.address ? <div className={styles.ContactForm__errormsg}>{errors.address}</div> : null}
              </label>
            </div>
            <div className={styles.ContactForm__item}>
              <label htmlFor="tel">
                <span className={styles.ContactForm__title}>電話番号</span>
                <Field name="tel" type="tel" className={styles.ContactForm__field} placeholder="000-000-0000" />
                {errors.tel && touched.tel ? <div className={styles.ContactForm__errormsg}>{errors.tel}</div> : null}
              </label>
            </div>
            <div className={styles.ContactForm__item}>
              <div className={styles.ContactForm__title}>お問い合わせ内容</div>
              <Field name="message" component="textarea" className={styles.ContactForm__textarea} placeholder="こちらにお問い合わせ内容についてお書きください"></Field>
              {errors.message && touched.message ? <div className={styles.ContactForm__errormsg}>{errors.message}</div> : null}
            </div>
            <div className={styles.ContactForm__errormsg}>
              <div>
                <ErrorMessage name="name" />
              </div>
              <div>
                <ErrorMessage name="furigana" />
              </div>
              <div>
                <ErrorMessage name="email" />
              </div>
              <div>
                <ErrorMessage name="emailconfirm" />
              </div>
              <div>
                <ErrorMessage name="address" />
              </div>
              <div>
                <ErrorMessage name="tel" />
              </div>
              <div>
                <ErrorMessage name="message" />
              </div>
            </div>
            {
              toggleState ?
                <div className={`${styles.ContactForm__btnList}`}>
                  <button type="button" className={`${styles.ContactForm__btn} ${styles.grayBtn}`} onClick={() => handleClick()}><span>修正</span></button>
                  <button type="submit" className={styles.ContactForm__btn}><span>送信</span></button>
                </div>

                :

                <div className={`${styles.ContactForm__btnList}`}>
                  <button type="button" className={styles.ContactForm__btn} onClick={() => handleClick()}><span>入力内容確認</span></button>
                </div>

            }
          </Form>
        )}
      </Formik>
    </div>
  )
};

export default ContactForm