// extracted by mini-css-extract-plugin
export var ContactForm = "ContactForm-module--ContactForm--3tV6M";
export var ContactForm__errormsg = "ContactForm-module--ContactForm__errormsg--1l00l";
export var ContactForm__item = "ContactForm-module--ContactForm__item--1Pb2g";
export var ContactForm__title = "ContactForm-module--ContactForm__title--hkWNs";
export var noafter = "ContactForm-module--noafter--33-9Y";
export var ContactForm__field = "ContactForm-module--ContactForm__field--61UaS";
export var ContactForm__textarea = "ContactForm-module--ContactForm__textarea--1l285";
export var ContactForm__btnList = "ContactForm-module--ContactForm__btnList--1UrfL";
export var grayBtn = "ContactForm-module--grayBtn--ScqY6";
export var ContactForm__btn = "ContactForm-module--ContactForm__btn--eJgcN";
export var confirm = "ContactForm-module--confirm--3dZnb";